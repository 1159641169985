<script lang="ts" setup>
// compiler macro
definePageMeta({
  layout: "page",
});

// no index and no follow
useHead({
  // noindex and nofollow
  meta: [
    {
      name: "robots",
      content: "noindex, nofollow",
    },
  ],
});
</script>

<template>
  <PageWrapper class="flex flex-col items-center justify-center">
    <Error :code="404" wrap />
  </PageWrapper>
</template>
